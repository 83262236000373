const PersonalData = {
    firstName: "Hari Krishna",
    lastName: "Tulisagari",
    // nickName:"<  killer - abhi >",
    mobNo:"+91 6301990836",
    email:"krishpateltulisagari789@gmail.com",
    address:"India",
    aboutMe:"Hi Everyone, I am Hari Krishna from Hyderabad,Telangana, India.I learned python, java,JavaScript etc, Currently Diving deepinto Web Development. Have a keen knowledge of databases like MySQL, MongoDB . Eager to contribute to innovative projects and gain hands-on experience in a dynamic and collaborative",
    resumeLink:'https://drive.google.com/file/d/1LE5figANKxlcgu6GNwWvauwFoOKRwWi9/view?usp=drive_link',
    imageUrl:''
}
export const autoTypeData=['Final Year CSE AIML grad', 'MERN Stack Developer','Curious Learner'];
export default PersonalData;
