const githubLink="https://github.com/krishpatel789";
const instaLink="https://www.instagram.com/urs_krishpatel/";
const linkedInLink="https://www.linkedin.com/in/krishpatel789/";
const twitterLink="https://x.com/mr_hk89?t=zIf4EvSXYRig9OrIFUz2xg&s=09";
const emailLink="mailto:krishpateltulisagari123@gmail.com";

const profileLinks={
    githubLink,
    instaLink,
    linkedInLink,
    twitterLink,
    emailLink
}
const SocialData=profileLinks;
export default SocialData;

